.noDataAvailable {
    display: flex;
    justify-content: center;
    color: grey;
    align-items: center;
}

.tableContainer {
    margin-top: 20px;
}
