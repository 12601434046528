@import "../../../mixins.module.scss";

.mainContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.graphContainer {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    h5 {
        @include mainContentSectionHeader;
    }
}
