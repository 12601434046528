.uploadDropzone {
    transition: background-color 300ms;
    p {
        margin: 5px 0px 0px 0px;
        color: #9c9c9c;
    }
    label {
        margin: 10px;
    }
    .title {
        text-align: center;
        font-size: 1.1rem;
    }
    .spaceforBorder {
        padding: 10px;
    }
    .titleWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .wrapper {
        box-sizing: border-box;
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 4px dashed #bfbfbf;
        border-radius: 5px;
        .uploadIcon {
            font-size: 1.5rem;
            margin-right: 10px;
            color: #bfbfbf;
            display: block;
        }
        .secondaryHelperText {
            font-size: 0.9rem;
        }
    }
    .inDropZone {
        background-color: #f8f8f8;
    }

    .fileIsValid {
        border: 4px dashed #17e54a;
    }
    .fileIsInvalid {
        border: 4px dashed #dd5050;
    }
    .rejectedFileTypeTextWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 20px 0px 0px;
        padding: 0px 30px;
        color: #dd5050;
        text-align: center;
        font-weight: bold;
        p {
            margin-bottom: 10px;
            color: #dd5050;
        }
        span {
            font-size: 1.5rem;
        }
    }
}

@media print {
    .uploadDropzone {
        display: none;
    }
}
