.rowIcons {
    color: #8e8e8e;
    display: flex;
    justify-content: flex-end;
    svg {
        font-size: 1rem;
    }
    button:nth-child(2) {
        &:hover {
            color: #ed4337;
        }
    }
    button:first-child {
        margin-right: 5px;
    }
}

.firstColumn {
    p {
        margin: 0px;
    }
    p:first-child {
        font-weight: bold;
    }
    p:nth-child(2) {
        font-size: 0.8rem;
        margin-top: 5px;
    }
}

.notesText {
    margin: 0px 0px 8px;
    max-width: 550px;
}

.fileChipsWrapper {
    display: flex;
    flex-wrap: wrap;
}

.fileChip {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
    padding: 4px 16px;
    max-width: 250px;
    margin: 5px 5px;
    p {
        margin: 0px;
    }
    span {
        margin-left: 15px;
    }
}

.fileChipHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-left: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 190px;
    }
}
