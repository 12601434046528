@mixin sectionBoxStyles {
    background: #fff;
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    -moz-box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    -webkit-box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
}

@mixin mainContentSectionStyles {
    padding: 15px 25px;
    border-radius: 10px;
}

@mixin mainContentSectionHeader {
    font-weight: 600;
    text-align: left;
    margin: 0px;
}
