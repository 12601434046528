@import "../../mixins.module.scss";

// Risks Report

.riskSummary {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        text-align: left;
        margin: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        width: 300px;
    }
    span {
        font-weight: bolder;
        text-align: right;
    }
}

.noDataDivWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .emptyIcon {
        font-size: 5rem;
        color: #a2a2a2;
        margin: 30px 0px;
    }
    .emptyText {
        text-align: center;
        font-size: 1.1rem;
        color: #a2a2a2;
        font-weight: bolder;
    }
}

// Pie Charts

.pieChartContainer {
    height: 360px;
}

.pieWrapper {
    height: 330px;
    padding-top: 20px;
}

.statusPieWrapper {
    position: relative;
    height: 100%;

    .legend {
        position: absolute;
        bottom: -5px;
        left: -5px;
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .colorCircle {
                border-radius: 50%;
                height: 12px;
                width: 12px;
                margin-right: 15px;
            }
            span {
                font-family: sans-serif;
                font-size: 12px;
                fill: rgb(33, 36, 39);
            }
        }
    }
    .fourlinesLegend {
        @extend .legend;
        bottom: -10px;
    }
}

@media screen and (max-width: 600px) {
    .pieChartContainer {
        max-height: 330px;
    }

    .pieWrapper {
        max-height: 200px;
        padding: 40px 0px;
    }

    .statusPieWrapper {
        .legend {
            bottom: -80px;
        }
    }
}

//GRID

.gridContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
}

.gridItem {
    background: #fcfcfc;
    border: 1px solid rgba(130, 130, 130, 0.1);

    @include mainContentSectionStyles;
    border-radius: 10px;
    padding: 20px;
    h5 {
        @include mainContentSectionHeader;
    }
}

.gridContainerSection {
    @include sectionBoxStyles;
    @include mainContentSectionStyles;
    border-radius: 10px;
    padding: 20px;
    h5 {
        @include mainContentSectionHeader;
    }
}

.graphToolTip {
    background-color: #f8f8f8;
    color: black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cecece;
    .colorBox {
        height: 10px;
        width: 10px;
        margin-right: 10px;
    }
    .projectNameWrapper {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #cecece;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    .valuesWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 160px;
        font-size: 0.85rem;
    }
    .value {
        display: flex;
        justify-content: space-between;
    }
    p {
        margin: 0;
    }
}

.portfolioReportArea {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
    color: #3c3c3c;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    .descriptionText {
        font-size: 0.9rem;
        text-align: left;
    }
    .fieldDescription {
        margin-top: 10px;
    }
    .portfolioCompDescription {
        margin-top: 5px;
    }
}

.reportHeader {
    font-weight: bold;
    text-align: left;
    display: inline-block;

    h3 {
        margin: 0px;
        display: inline-block;
    }

    .lineDecoration {
        height: 2px;
        background-color: #ec1027;
    }
}

.topLineOverview {
    display: flex;
    justify-content: space-between;
}

.activeReportsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.9rem;
    p {
        margin: 0px 30px 0px 0px;
    }
    .activeReportText {
        display: inline-block;
        margin-right: 10px;
        font-weight: bolder;
    }
    .activeReportNumber {
        display: inline-block;
        min-width: 20px;
        height: 16px;
        background: #ea151f;
        border-radius: 10px;
        padding: 0px 5px 4px 5px;
        color: #fff;
        font-weight: bolder;
    }
}

.printTableHeader {
    thead {
        //Removes repeating header when Project list spans multiple pages in print view
        display: table-row-group;
        tr {
            page-break-inside: avoid;
        }
    }
}

@media screen {
    .reportPrintVersion {
        visibility: hidden;
    }
}

@media print and (orientation: portrait) {
    .portfolioReportArea {
        height: inherit;
        overflow: hidden;
    }

    .gridContainerSection {
        padding: 0px;
        box-shadow: unset;
    }

    .gridItem {
        padding: 0px;
        background-color: #fff;
        border: none;
        height: 300px;
        flex-basis: 100%;
    }

    .activeReportNumber {
        background-color: #fff !important;
        color: #000 !important;
        font-weight: bolder;
    }

    .portEvalBubbleChart {
        height: 300px;
        margin: 30px 0px 60px;
    }

    .printPhaseBarChartsWrapper {
        margin: 30px 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .portfolioCompPieChartsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .statusPieChartsWrapper {
        margin-top: 40px;
        height: 100%;
        width: 100%;
    }
}

@media print and (orientation: landscape) {
    .portfolioCompSection {
        margin-top: 1000px;
    }

    .portfolioReportArea {
        height: inherit;
        overflow: hidden;
    }

    .gridContainerSection {
        padding: 0px;
        box-shadow: unset;
    }

    .gridItem {
        padding: 0px;
        background-color: #fff;
        border: none;
        height: 300px;
        flex-basis: 100%;
    }

    .activeReportNumber {
        background-color: #fff !important;
        color: #000 !important;
        font-weight: bolder;
    }

    .portEvalBubbleChart {
        height: 300px;
        margin: 30px 0px 60px;
    }

    .printPhaseBarChartsWrapper {
        margin: 30px 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .barchartOne,
        .barchartTwo {
            width: 500px !important;
        }
    }
    .portfolioCompPieChartsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pieChart {
            width: 300px;
        }
    }

    .statusPieChartsWrapper {
        margin-top: 40px;
        .gridItem {
            flex-basis: unset;
        }
    }
}
