.app {
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.mobileSidebarToggleIcon {
    top: 30px;
    left: 30px;
    position: absolute;
    cursor: pointer;
}

//Sidebar

.sidebar {
    box-sizing: border-box;
    height: 100vh;
    width: 20em;
    padding: 10px 10px;
    text-align: left;
    position: relative;
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    -moz-box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    -webkit-box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
}

.mobileSidebarToggle {
    top: 30px;
    right: 20px;
    position: absolute;
}

.sidebarLogo {
    background-image: url("../public/kanton-Bern-Logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 80px;
    width: 100%;
}

.routeLinksList {
    margin-top: 35px;

    .sidebarHeader {
        background-color: #fcf8f3;
        padding: 8px 20px;
        border-radius: 10px;
    }

    h4 {
        margin: 0px;
        cursor: default;
    }

    ul {
        list-style-type: none;
        padding: 0px 10px;
        margin: 0px;

        li {
            font-size: 0.95em;
        }
    }
}

.iconLinkWrapper {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding: 0px;

    p {
        display: inline-block;
        margin: 8px 0px;
    }
    p::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: rgba(236, 16, 39, 0.7);
        transition: width 0.3s;
    }
    p:hover::after {
        width: 100%;
    }

    .sidebarLinkIcon {
        color: #8e8e8e;
        display: flex;
        justify-content: center;
        width: 20px;
        margin: 0px 15px 0px 0px;
    }
}

//Main content

.langSelect {
    border: 2px solid #ededed;
    border-radius: 5px;
    padding: 2px 6px 2px 0px;
    font-size: 1.2em;
    &:hover {
        background-color: #ededed;
    }
}

.langSwitcher {
    position: absolute;
    top: 30px;
    right: 40px;

    span:nth-child(2),
    span:nth-child(4) {
        margin: 0px 5px;
    }

    span:nth-child(1),
    span:nth-child(3),
    span:nth-child(5) {
        cursor: pointer;
        transition: color 200ms;
        &:hover {
            color: #474747;
        }
    }
}

.mainContent {
    height: 100vh;
    box-sizing: border-box;
    width: 100%;
    background-color: #f8f8f8;
    padding: 30px 40px;
    overflow-y: auto;
    h2 {
        margin: 0px;
    }
}

.notFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    svg {
        color: rgb(237, 67, 55);
    }
    h2 {
        margin-top: 20px;
        color: #474747;
    }
}

.errorPage {
    @extend .notFoundPage;
    p {
        margin: 0px;
    }
}

@media screen and (max-width: 800px) {
    .mainContent {
        padding: 90px 20px 30px;
    }
    .langSwitcher {
        right: 20px;
    }
    .mobileSidebarToggleIcon {
        left: 20px;
    }
}

@media print {
    .mainContent {
        height: auto;
        overflow: hidden;
        background-color: #fff;
    }

    .mobileSidebarToggleIcon {
        display: none;
    }
}
